import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import '../Home/NavHomeBar.css'

function NavBar() {
  return (
    <Navbar className='nav py-4' expand="lg" variant="dark">
      <Container>
        <Navbar.Brand href="#"></Navbar.Brand>
            <div className='d-flex '>
                <NavDropdown title="Barredoras" id="nav-dropdown-barredoras">
                    <NavDropdown.Item href="/SW750B">SW750 B</NavDropdown.Item>
                    <NavDropdown.Item href="/SW5500">SW5500 B</NavDropdown.Item>
                    <NavDropdown.Item href="/SR1601">SR1601 LPG</NavDropdown.Item>
                    <NavDropdown.Item href="/CS7010">CS7010 LPG</NavDropdown.Item>
                    <NavDropdown.Item href="/SW8000">SW8000 LPG</NavDropdown.Item>
                    <NavDropdown.Item href="/ROS1300">ROS 1300</NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title="Fregadoras" id="nav-dropdown-fregadoras">
                    <NavDropdown.Item href="/SC250B">SC250 B</NavDropdown.Item>
                    <NavDropdown.Item href="/SC351">SC351 B</NavDropdown.Item>
                    <NavDropdown.Item href="/BR855">BR855 B</NavDropdown.Item>
                    <NavDropdown.Item href="/SC6000">SC6000 B</NavDropdown.Item>
                    <NavDropdown.Item href="/SC6500B">SC6500 B</NavDropdown.Item>
                    <NavDropdown.Item href="/AS4325">AS 4325 B</NavDropdown.Item>
                    <NavDropdown.Item href="/AS510B">AS 510 B</NavDropdown.Item>
                    <NavDropdown.Item href="/AS530R">AS 530 R</NavDropdown.Item>
                    <NavDropdown.Item href="/AS710R">AS 710 R</NavDropdown.Item>
                    <NavDropdown.Item href="/AS1050">AS1050 R</NavDropdown.Item>
                    <NavDropdown.Item href="/AS850">AS 850 R</NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title="Combinadas" id="nav-dropdown-combinadas">
                    <NavDropdown.Item href="#action17">EN CONSTRUCIÓN</NavDropdown.Item> 
                </NavDropdown>

                <NavDropdown title="Aspiradoras" id="nav-dropdown-aspiradoras">
                    <NavDropdown.Item href="#action17">EN CONSTRUCIÓN</NavDropdown.Item>
                </NavDropdown>
            </div>
      </Container>
    </Navbar>
  );
}

export default NavBar;