export const obtenerSDP_SC250 = async () => {
    try {
      const response = await fetch('/data/SC250/infoSolucionDeProblemas.json', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error("Error fetching data from JSON:", error);
      return [];
    }
};

export const obtenerSDP_ROS1300 = async () => {
    try {
      const response = await fetch('/data/ROS1300/infoSolucionDeProblemas.json', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error("Error fetching data from JSON:", error);
      return [];
    }
};

export const obtenerSDP_SC351B = async () => {
    try {
      const response = await fetch('/data/SC351B/infoSolucionDeProblemas.json', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error("Error fetching data from JSON:", error);
      return [];
    }
};

export const obtenerSDP_SC6000 = async () => {
    try {
      const response = await fetch('/data/SC6000/infoSolucionDeProblemas.json', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error("Error fetching data from JSON:", error);
      return [];
    }
};

export const obtenerSDP_SC6500 = async () => {
    try {
      const response = await fetch('/data/SC6500/infoSolucionDeProblemas.json', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error("Error fetching data from JSON:", error);
      return [];
    }
};

export const obtenerSDP_SR1601LPG = async () => {
  try {
    const response = await fetch('/data/SR1601LPG/infoSolucionDeProblemas.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data from JSON:", error);
    return [];
  }
};

export const obtenerSDP_BR855 = async () => {
  try {
    const response = await fetch('/data/BR855/infoSolucionDeProblemas.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data from JSON:", error);
    return [];
  }
};

export const obtenerSDP_CS7010 = async () => {
  try {
    const response = await fetch('/data/CS7010/infoSolucionDeProblemas.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data from JSON:", error);
    return [];
  }
};