import { useEffect, useState } from "react"; 

import NavBar from '../components/NavBar/NavBar'
import Banner from '../Componentes/Banners/Banner';
import UsoDelEquipo5C from '../Componentes/UsoDelEquipo/UsoDelEquipo';
import AlfinalizarElTrabajo5C from '../Componentes/AlFinalizarElTrabajo/AlFinalizarElTrabajoFix';
import SolucionDeProblemas from '../Componentes/SolucionDeProblemas/SolucionDeProblemas';
import Video from "../Componentes/Video/video";
import ManualesYDocumentos from '../Componentes/ManualesYDocumentos/ManualesYDocumentos2item.';
import RedesSociales from '../Componentes/RedesSociales/RedesSociales';
import Footer from '../Componentes/Footer/Footer'
import InfoPrincipal from '../Componentes/InfoPrincipal/InfoPrincipal';

import { obtenerDataBanner } from '../Componentes/infoJsons/infoBanner.js'
import { obtenerInfoPrin_CS7010 } from '../Componentes/infoJsons/infoPrincipal.js'
import { obtenerUSO_CS7010 } from '../Componentes/infoJsons/infoUsoDelEquipo.js'
import { obtenerAlfinalizarCS7010 } from "../Componentes/infoJsons/infoAlFinalizar.js";
import { obtenerSDP_CS7010 } from '../Componentes/infoJsons/infoSoluDeProble.js';

const CS7010GLPH = () => {

      //Banner
      const [data, setData] = useState([]);
      //
    
      //inFo Principal
        const [infoPrinc, setInfoPrinc] = useState([]);
      //
    
      //UsoDelEquipo
        const [USO, setUSO] = useState([]);
      //
    
      //AL FINALIZAR EL TRABAJO
        const [final, setFinal] = useState([]);
      //

      //Solucion de Problemas
        const [SDP, setSDP] = useState([]);
      //
    
        useEffect(() => {
    
            //Banner
            obtenerDataBanner().then(myJson => {setData(myJson);});
    
            //INFOPRINCIPAL
            obtenerInfoPrin_CS7010().then(infoPrincipal => {setInfoPrinc(infoPrincipal);});
    
            //USODELEQUIPO
            obtenerUSO_CS7010().then(uso => {setUSO(uso);});
            //
    
            //AL FINALIZAR EL TRABAJO
            obtenerAlfinalizarCS7010().then(final => {setFinal(final);});
            //

            //SOLUCION DE PROBLEMA
            obtenerSDP_CS7010().then(SDP => {setSDP(SDP)});
            //
        }, []);

        const listas = [
        ]
        
        const listasDesc2 = [
          "Con el Sistema DustGuard reduce las partículas de polvo en suspensión.",
          "Con el sistema SmartFlow puede ajustar el caudal de agua y químicos de limpieza."
        ]

        const listasDesc3 = []



        const videos = [
            "https://nilfiskchile.cl/assets/videos/CS7010/CS7010_guia_inicio_rapido_espannol_Nilfisk.mp4"
          ];
          
          const posters = [
            "https://nilfiskchile.cl/assets/videos/CS7010/CS7010_video.jpg"
          ];
          
          const thumbnails = [
            "https://nilfiskchile.cl/assets/videos/CS7010/CS7010_video.jpg"
          ];

          const elementosCarrusel = [
            {
              imgSrc: "https://nilfiskchile.cl/assets/img/CS7010/Manuales/uso-y-mantencion.jpg",
              pdf: "https://nilfiskchile.cl/assets/img/CS7010/Manuales/CS7010_ Uso_mantención_español_Chile.pdf",
              label: 'Uso y Mantenimiento',
            },
            {
              imgSrc: "https://nilfiskchile.cl/assets/img/CS7010/Manuales/catalogo.jpg",
              pdf: "https://nilfiskchile.cl/assets/img/CS7010/Manuales/CS7010_Catálogo_español_Chile.pdf",
              label: 'Catálogo',
            }
          ];

    return (

        <div>
            <NavBar/>
            <Banner imageClass="BannerFregadora" titulo={data.length > 0 ? data[0].tituloBarredora : ""} />
            <InfoPrincipal data={infoPrinc} listas={listas} listasDesc2={listasDesc2} listasDesc3={listasDesc3}/>
            <UsoDelEquipo5C data={USO}/>
            <AlfinalizarElTrabajo5C data={final} />
            <SolucionDeProblemas problemas={SDP}/>
            <Video videos={videos} posters={posters} thumbnails={thumbnails}  />
            <ManualesYDocumentos elementosCarrusel={elementosCarrusel} />
            <RedesSociales />
            <Footer />
        </div>

    );
};

export default CS7010GLPH;