export const obtenerAlfinalizarJson = async () => {
    try {
          const response = await fetch('/data/AS510B/infoAlFinalizar.json', {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });
          const jsonData = await response.json();
          return jsonData;
        } catch (error) {
          console.error("Error fetching data from JSON:", error);
          return [];
        }
};

export const obtenerAlfinalizarSC250 = async () => {
    try {
          const response = await fetch('/data/SC250/infoAlFinalizar.json', {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });
          const jsonData = await response.json();
          return jsonData;
        } catch (error) {
          console.error("Error fetching data from JSON:", error);
          return [];
        }
};

export const obtenerAlfinalizarAS710R = async () => {
    try {
          const response = await fetch('/data/AS710R/infoAlFinalizar.json', {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });
          const jsonData = await response.json();
          return jsonData;
        } catch (error) {
          console.error("Error fetching data from JSON:", error);
          return [];
        }
};

export const obtenerAlfinalizarROS1300 = async () => {
    try {
          const response = await fetch('/data/ROS1300/infoAlFinalizar.json', {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });
          const jsonData = await response.json();
          return jsonData;
        } catch (error) {
          console.error("Error fetching data from JSON:", error);
          return [];
        }
};

export const obtenerAlfinalizarAS4325 = async () => {
    try {
          const response = await fetch('/data/AS4325/infoAlFinalizar.json', {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });
          const jsonData = await response.json();
          return jsonData;
        } catch (error) {
          console.error("Error fetching data from JSON:", error);
          return [];
        }
};

export const obtenerAlfinalizarAS1050R = async () => {
    try {
          const response = await fetch('/data/AS1050R/infoAlFinalizar.json', {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });
          const jsonData = await response.json();
          return jsonData;
        } catch (error) {
          console.error("Error fetching data from JSON:", error);
          return [];
        }
};

export const obtenerAlfinalizarSC351B = async () => {
    try {
          const response = await fetch('/data/SC351B/infoAlFinalizar.json', {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });
          const jsonData = await response.json();
          return jsonData;
        } catch (error) {
          console.error("Error fetching data from JSON:", error);
          return [];
        }
};

export const obtenerAlfinalizarSW750B = async () => {
    try {
          const response = await fetch('/data/SW750B/infoAlFinalizar.json', {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });
          const jsonData = await response.json();
          return jsonData;
        } catch (error) {
          console.error("Error fetching data from JSON:", error);
          return [];
        }
};

export const obtenerAlfinalizarSC6000 = async () => {
    try {
          const response = await fetch('/data/SC6000/infoAlFinalizar.json', {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });
          const jsonData = await response.json();
          return jsonData;
        } catch (error) {
          console.error("Error fetching data from JSON:", error);
          return [];
        }
};

export const obtenerAlfinalizarAS850R = async () => {
  try {
        const response = await fetch('/data/AS850R/infoAlFinalizar.json', {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });
        const jsonData = await response.json();
        return jsonData;
      } catch (error) {
        console.error("Error fetching data from JSON:", error);
        return [];
      }
};

export const obtenerAlfinalizarAS530R = async () => {
  try {
        const response = await fetch('/data/AS530R/infoAlFinalizar.json', {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });
        const jsonData = await response.json();
        return jsonData;
      } catch (error) {
        console.error("Error fetching data from JSON:", error);
        return [];
      }
};

export const obtenerAlfinalizarSW8000 = async () => {
  try {
        const response = await fetch('/data/SW8000/infoAlFinalizar.json', {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });
        const jsonData = await response.json();
        return jsonData;
      } catch (error) {
        console.error("Error fetching data from JSON:", error);
        return [];
      }
};

export const obtenerAlfinalizarCS7010 = async () => {
  try {
        const response = await fetch('/data/CS7010/infoAlFinalizar.json', {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });
        const jsonData = await response.json();
        return jsonData;
      } catch (error) {
        console.error("Error fetching data from JSON:", error);
        return [];
      }
};