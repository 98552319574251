import { useEffect, useState } from "react"; 

import NavBar from '../components/NavBar/NavBar'
import Banner from '../Componentes/Banners/Banner';
import UsoDelEquipo from '../Componentes/UsoDelEquipo/UsoDelEquipo';
import AlfinalizarElTrabajo5C from '../Componentes/AlFinalizarElTrabajo/AlFinalizarElTrabajo_5C';
import SolucionDeProblemas from '../Componentes/SolucionDeProblemas/SolucionDeProblemas';
import Video from "../Componentes/Video/video";
import ManualesYDocumentos from '../Componentes/ManualesYDocumentos/ManuealesYDocumentos';
import RedesSociales from '../Componentes/RedesSociales/RedesSociales';
import Footer from '../Componentes/Footer/Footer'
import InfoPrincipal from '../Componentes/InfoPrincipal/InfoPrincipal';

import { obtenerDataBanner } from '../Componentes/infoJsons/infoBanner.js'
import { obtenerInfoPrin_SC351B } from '../Componentes/infoJsons/infoPrincipal.js'
import { obtenerUSO_SC351B } from '../Componentes/infoJsons/infoUsoDelEquipo.js'
import { obtenerAlfinalizarSC351B } from "../Componentes/infoJsons/infoAlFinalizar.js";
import { obtenerSDP_SC351B } from '../Componentes/infoJsons/infoSoluDeProble.js';

const SC351B = () => {

      //Banner
      const [data, setData] = useState([]);
      //
    
      //inFo Principal
        const [infoPrinc, setInfoPrinc] = useState([]);
      //
    
      //UsoDelEquipo
        const [USO_SC315B, setUSO_SC315B] = useState([]);
      //
    
      //AL FINALIZAR EL TRABAJO
        const [final, setFinal] = useState([]);
      //

      //Solucion de Problemas
        const [SDP_SC315B, setSDP_SC315B] = useState([]);
      //
    
        useEffect(() => {
    
            //Banner
            obtenerDataBanner().then(myJson => {setData(myJson);});
    
            //INFOPRINCIPAL
            obtenerInfoPrin_SC351B().then(infoPrincipal => {setInfoPrinc(infoPrincipal);});
    
            //USODELEQUIPO
            obtenerUSO_SC351B().then(uso => {setUSO_SC315B(uso);});
            //
    
            //AL FINALIZAR EL TRABAJO
            obtenerAlfinalizarSC351B().then(final => {setFinal(final);});
            //

            //SOLUCION DE PROBLEMA
            obtenerSDP_SC351B().then(SDP => {setSDP_SC315B(SDP)});
            //
        }, []);

        const listas = [
          
        ]
        
        const listasDesc2 = [
          "Limpiar en 2 direcciones, hacia adelante y hacia atrás, con excelentes resultados",
          "Su tamaño compacto le permitirá llegar a todos los rincones e incluso limpiar debajo de mesas, muebles y estanterías."
        ]
        const listasDesc3 = []



        const videos = [
            "https://nilfiskchile.cl/assets/videos/F-SC351B/SC351_guia_inicio_rapido_espanol_Nilfisk.mov",
            "https://nilfiskchile.cl/assets/videos/F-SC351B/SC351_espacios_pequenos_espanol_NIlfisk.mov"
          ];
          
          const posters = [
            "https://nilfiskchile.cl/assets/videos/F-SC351B/sc351-video.jpg",
            "https://nilfiskchile.cl/assets/videos/F-SC351B/sc351-video-2.jpg"
          ];
          
          const thumbnails = [
            "https://nilfiskchile.cl/assets/videos/F-SC351B/sc351-video.jpg",
            "https://nilfiskchile.cl/assets/videos/F-SC351B/sc351-video-2.jpg"
          ];

          const elementosCarrusel = [
            {
              imgSrc: "https://nilfiskchile.cl/assets/img/FregadoraSC351B/Manuales/catalogo.jpg",
              pdf: "https://nilfiskchile.cl/assets/img/FregadoraSC351B/Manuales/SC351 B_Catálogo_español_Chile.pdf",
              label: 'Catálogo',
            },
            {
              imgSrc: "https://nilfiskchile.cl/assets/img/FregadoraSC351B/Manuales/uso-y-mantencion.jpg",
              pdf: "https://nilfiskchile.cl/assets/img/FregadoraSC351B/Manuales/SC351 B_ Uso_mantención_español_Chile.pdf",
              label: 'Uso y Mantencion',
            },
            {
              imgSrc: "https://nilfiskchile.cl/assets/img/FregadoraSC351B/Manuales/ficha-tecnica.jpg",
              pdf: "https://nilfiskchile.cl/assets/img/FregadoraSC351B/Manuales/SC351 B_Ficha Técnica_español.pdf",
              label: 'Ficha Técnica',
            },
          ];

    return (

        <div>
            <NavBar/>
            <Banner imageClass="BannerFregadora" titulo={data.length > 0 ? data[0].tituloBarredora : ""} />
            <InfoPrincipal data={infoPrinc} listas={listas} listasDesc2={listasDesc2} listasDesc3={listasDesc3}/>
            <UsoDelEquipo data={USO_SC315B}/>
            <AlfinalizarElTrabajo5C data={final} />
            <SolucionDeProblemas problemas={SDP_SC315B}/>
            <Video videos={videos} posters={posters} thumbnails={thumbnails}  />
            <ManualesYDocumentos elementosCarrusel={elementosCarrusel} />
            <RedesSociales />
            <Footer />
        </div>

    );
};

export default SC351B;