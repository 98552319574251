import { useEffect, useState } from "react"; 

import NavBar from '../components/NavBar/NavBar'
import Banner from '../Componentes/Banners/Banner';
import UsoDelEquipo5C from '../Componentes/UsoDelEquipo/UsoDelEquipo';
import SolucionDeProblemas from '../Componentes/SolucionDeProblemas/SolucionDeProblemas';
import ManualesYDocumentos from '../Componentes/ManualesYDocumentos/ManualesYDocumentos1item';
import RedesSociales from '../Componentes/RedesSociales/RedesSociales';
import Footer from '../Componentes/Footer/Footer'
import InfoPrincipal from '../Componentes/InfoPrincipal/InfoPrincipal';

import { obtenerDataBanner } from '../Componentes/infoJsons/infoBanner.js'
import { obtenerInfoPrin_SR1601GLP } from '../Componentes/infoJsons/infoPrincipal.js'
import { obtenerUSO_SR1601LPG } from '../Componentes/infoJsons/infoUsoDelEquipo.js'
import { obtenerSDP_SR1601LPG } from '../Componentes/infoJsons/infoSoluDeProble.js';

const SR1601LPG = () => {

      //Banner
      const [data, setData] = useState([]);
      //
    
      //inFo Principal
        const [infoPrinc, setInfoPrinc] = useState([]);
      //
    
      //UsoDelEquipo
        const [USO_SR1601LPG, setUSO_SR1601LPG] = useState([]);
      //



      //Solucion de Problemas
        const [SDP_SR1601LPG, setSDP_SR1601LPG] = useState([]);
      //
    
        useEffect(() => {
    
            //Banner
            obtenerDataBanner().then(myJson => {setData(myJson);});
    
            //INFOPRINCIPAL
            obtenerInfoPrin_SR1601GLP().then(infoPrincipal => {setInfoPrinc(infoPrincipal);});
    
            //USODELEQUIPO
            obtenerUSO_SR1601LPG().then(uso => {setUSO_SR1601LPG(uso);});
            //
      

            //SOLUCION DE PROBLEMA
            obtenerSDP_SR1601LPG().then(SDP => {setSDP_SR1601LPG(SDP)});
            //
        }, []);

        const listas = [
        ]
        
        const listasDesc2 = [
        ]

        const listasDesc3 = []

          const elementosCarrusel = [
            {
              imgSrc: "https://nilfiskchile.cl/assets/img/SR1601LPG/Manuales/uso-y-mantencion.jpg",
              pdf: "https://nilfiskchile.cl/assets/img/SR1601LPG/Manuales/SR1601 LPG_ Uso_mantención_español_Chile.pdf",
              label: 'Uso & Cuidado',
            }
          ];

    return (

        <div>
            <NavBar/>
            <Banner imageClass="BannerFregadora" titulo={data.length > 0 ? data[0].tituloBarredora : ""} />
            <InfoPrincipal data={infoPrinc} listas={listas} listasDesc2={listasDesc2} listasDesc3={listasDesc3}/>
            <UsoDelEquipo5C data={USO_SR1601LPG}/>
            <SolucionDeProblemas problemas={SDP_SR1601LPG}/>
            <ManualesYDocumentos elementosCarrusel={elementosCarrusel} />
            <RedesSociales />
            <Footer />
        </div>

    );
};

export default SR1601LPG;