export const obtenerInfoPrin_AS510B = async () => {
  try {
    const response = await fetch('/data/AS510B/infoPrincipal.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data from JSON:", error);
    return [];
  }
};

export const obtenerInfoPrin_SC250 = async () => {
  try {
    const response = await fetch('/data/SC250/infoPrincipal.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data from JSON:", error);
    return [];
  }
};

export const obtenerInfoPrin_AS710R = async () => {
  try {
    const response = await fetch('/data/AS710R/infoPrincipal.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data from JSON:", error);
    return [];
  }
};

export const obtenerInfoPrin_ROS1300 = async () => {
  try {
    const response = await fetch('/data/ROS1300/infoPrincipal.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data from JSON:", error);
    return [];
  }
};

export const obtenerInfoPrin_AS4325 = async () => {
  try {
    const response = await fetch('/data/AS4325/infoPrincipal.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data from JSON:", error);
    return [];
  }
};

export const obtenerInfoPrin_AS530R = async () => {
  try {
    const response = await fetch('/data/AS530R/infoPrincipal.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data from JSON:", error);
    return [];
  }
};

export const obtenerInfoPrin_AS1050R = async () => {
  try {
    const response = await fetch('/data/AS1050R/infoPrincipal.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data from JSON:", error);
    return [];
  }
};

export const obtenerInfoPrin_SC351B = async () => {
  try {
    const response = await fetch('/data/SC351B/infoPrincipal.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data from JSON:", error);
    return [];
  }
};

export const obtenerInfoPrin_SW750B = async () => {
  try {
    const response = await fetch('/data/SW750B/infoPrincipal.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data from JSON:", error);
    return [];
  }
};

export const obtenerInfoPrin_SC6500 = async () => {
  try {
    const response = await fetch('/data/SC6500/infoPrincipal.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data from JSON:", error);
    return [];
  }
};

export const obtenerInfoPrin_SC6000 = async () => {
  try {
    const response = await fetch('/data/SC6000/infoPrincipal.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data from JSON:", error);
    return [];
  }
};

export const obtenerInfoPrin_SR1601GLP = async () => {
  try {
    const response = await fetch('/data/SR1601LPG/infoPrincipal.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data from JSON:", error);
    return [];
  }
};

export const obtenerInfoPrin_SW550B = async () => {
  try {
    const response = await fetch('/data/SW5500B/infoPrincipal.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data from JSON:", error);
    return [];
  }
};

export const obtenerInfoPrin_SW8000 = async () => {
  try {
    const response = await fetch('/data/SW8000/infoPrincipal.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data from JSON:", error);
    return [];
  }
};

export const obtenerInfoPrin_AS850R = async () => {
  try {
    const response = await fetch('/data/AS850R/infoPrincipal.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data from JSON:", error);
    return [];
  }
};

export const obtenerInfoPrin_BR855 = async () => {
  try {
    const response = await fetch('/data/BR855/infoPrincipal.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data from JSON:", error);
    return [];
  }
};

export const obtenerInfoPrin_CS7010 = async () => {
  try {
    const response = await fetch('/data/CS7010/infoPrincipal.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data from JSON:", error);
    return [];
  }
};