import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import { BrowserRouter, Route, Routes,  Navigate} from 'react-router-dom';

/* Primera pagina*/
import Inicio from './view/Inicio';
import LimpiezaInteligente from './view/LimpiezaInteligente';

/* Prototipo pagina */
import PN9087380020 from './view/InfoFregadoras/FregadoraSC250';
import FregadoraAS510 from './view/InfoFregadoras/FregadoraAS510';

/* Nilfisk Fregadoras */
import SC250 from './Templates/SC250'                         /* https://nilfiskchile.cl/sc250b */
import SC351B from './Templates/SC351B';                      /* https://nilfiskchile.cl/sc351  */
import BR855 from './Templates/BR855';                        /* https://nilfiskchile.cl/br855 */
import SC6000 from './Templates/SC6000';                      /* https://nilfiskchile.cl/sc6000 */
import SC6500 from './Templates/SC6500';                      /* https://nilfiskchile.cl/sc6500b */

/* Nilfisk Barredoras */
import SW750B from './Templates/SW750B';                      /* https://nilfiskchile.cl/sw750b */
import SW5500B from './Templates/SW5500B';                    /* https://nilfiskchile.cl/sw5500 */
import SR1601LPG from './Templates/SR1601LPG';                /* https://nilfiskchile.cl/sr1601 */
import CS7010GLPH from './Templates/CS7010GLPH';              /* https://nilfiskchile.cl/cs7010 */
import SW8000 from './Templates/SW8000';                      /* https://nilfiskchile.cl/sw8000 */

/* Viper Fregadoras */
import AS4325B  from './Templates/AS4325B';                   /* https://nilfiskchile.cl/AS4325 */
import AS510B from './Templates/AS510B';                      /* https://nilfiskchile.cl/as510b */
import AS530R from './Templates/AS530R';                      /* https://nilfiskchile.cl/as530r */
import AS710R from './Templates/AS710R';                      /* https://nilfiskchile.cl/as710r */
import AS1050R from './Templates/AS1050R';                    /* https://nilfiskchile.cl/as1050 */
import AS850 from './Templates/AS850';                        /* https://nilfiskchile.cl/as850  */

/* Viper Barredoras */
import ROS1300 from './Templates/ROS1300';                    /* https://nilfiskchile.cl/ros1300 */

/* Menu */
import MenuInicial from './Templates/MenuInicio';

 
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>    
          <Route path='/SC6000' element={<SC6000/>}/>
          <Route path='/SC6500B' element={<SC6500/>}/>
          <Route path='/AS850' element={<AS850/>}/>
          <Route path='/SW8000' element={<SW8000/>}/>
          <Route path='/CS7010' element={<CS7010GLPH/>}/>
          <Route path='/SR1601' element={<SR1601LPG/>}/>
          <Route path='/SW5500' element={<SW5500B/>}/>
          <Route path='/SW750B' element={<SW750B/>}/>
          <Route path='/BR855' element={<BR855/>}/>
          <Route path='/SC351' element={<SC351B/>}/>
          <Route path='/AS1050' element={<AS1050R/>}/>
          <Route path='/AS530R' element={<AS530R/>}/>
          <Route path='/AS4325' element={<AS4325B/>}/>
          <Route path='/ROS1300' element={<ROS1300/>}/>
          <Route path='/AS710R' element={<AS710R/>}/>
          <Route path='/SC250B' element={<SC250/>}/>
          <Route path='/AS510B' element={<AS510B/>}/>
          <Route path='/AS510B-ANTIGUO' element={<FregadoraAS510/>}/>
          <Route path='/SC250-ANTIGUO' element={<PN9087380020/>} />
          <Route path="/limpiezainteligente" element={<LimpiezaInteligente />} />
          <Route path="/" element={<Inicio />} />
          <Route path="/Inicio" element={<MenuInicial />} />
          <Route path='*' render={() => <Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
