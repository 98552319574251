import { useEffect, useState } from "react"; 

import NavBar from '../components/NavBar/NavBar'
import Banner from '../Componentes/Banners/Banner';
import UsoDelEquipo5C from '../Componentes/UsoDelEquipo/UsoDelEquipo';
import AlfinalizarElTrabajo from '../Componentes/AlFinalizarElTrabajo/AlFinalizarElTrabajo';
import Video from "../Componentes/Video/video";
import ManualesYDocumentos from '../Componentes/ManualesYDocumentos/ManualesYDocumentos2item.';
import RedesSociales from '../Componentes/RedesSociales/RedesSociales';
import Footer from '../Componentes/Footer/Footer'
import InfoPrincipal from '../Componentes/InfoPrincipal/InfoPrincipal';

import { obtenerDataBanner } from '../Componentes/infoJsons/infoBanner.js'
import { obtenerInfoPrin_SW8000 } from '../Componentes/infoJsons/infoPrincipal.js'
import { obtenerUSO_SW8000 } from '../Componentes/infoJsons/infoUsoDelEquipo.js'
import { obtenerAlfinalizarSW8000 } from '../Componentes/infoJsons/infoAlFinalizar.js'

const SW8000 = () => {

      //Banner
      const [data, setData] = useState([]);
      //
    
      //inFo Principal
        const [infoPrinc, setInfoPrinc] = useState([]);
      //
    
      //UsoDelEquipo
        const [USO, setUSO] = useState([]);
      //

      //AL FINALIZAR EL TRABAJO
      const [final, setFinal] = useState([]);
      //

    
        useEffect(() => {
    
            //Banner
            obtenerDataBanner().then(myJson => {setData(myJson);});
    
            //INFOPRINCIPAL
            obtenerInfoPrin_SW8000().then(infoPrincipal => {setInfoPrinc(infoPrincipal);});
    
            //USODELEQUIPO
            obtenerUSO_SW8000().then(uso => {setUSO(uso);});
            //

            //AL FINALIZAR EL TRABAJO
            obtenerAlfinalizarSW8000().then(final => {setFinal(final);});
            //

        }, []);

        const listas = [
        ]
        
        const listasDesc2 = [
        ]
        const listasDesc3 = []



        const videos = [
            "https://nilfiskchile.cl/assets/videos/B-SW8000/SW8000 LPG_Guía de Uso_español.mov"
          ];
          
          const posters = [
            "https://nilfiskchile.cl/assets/videos/B-SW8000/sw8000_video.jpg"
          ];
          
          const thumbnails = [
            "https://nilfiskchile.cl/assets/videos/B-SW8000/sw8000_video.jpg"
          ];

          const elementosCarrusel = [
            {
              imgSrc: "https://nilfiskchile.cl/assets/img/SW8000/Manuales/uso-y-mantencion.jpg",
              pdf: "https://nilfiskchile.cl/assets/img/SW8000/Manuales/SW8000 LPG_Uso_mantencion_espannol_Chile.pdf",
              label: 'Uso y Mantenimiento',
            },
            {
              imgSrc: "https://nilfiskchile.cl/assets/img/SW8000/Manuales/catalogo.jpg",
              pdf: "https://nilfiskchile.cl/assets/img/SW8000/Manuales/SW8000 LPG_Catalogo_espannol_Chile.pdf",
              label: 'Catálogo',
            }
          ];

    return (

        <div>
            <NavBar/>
            <Banner imageClass="BannerFregadora" titulo={data.length > 0 ? data[0].tituloBarredora : ""} />
            <InfoPrincipal data={infoPrinc} listas={listas} listasDesc2={listasDesc2} listasDesc3={listasDesc3}/>
            <UsoDelEquipo5C data={USO}/>
            <AlfinalizarElTrabajo data={final} />
            <Video videos={videos} posters={posters} thumbnails={thumbnails}  />
            <ManualesYDocumentos elementosCarrusel={elementosCarrusel} />
            <RedesSociales />
            <Footer />
        </div>

    );
};

export default SW8000;